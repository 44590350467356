//
//  SDKRemoteTokenValue.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
//  This file defines all token value containers for the actual  model - this model will be exactly available to users
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { BlurType } from "../enums/SDKBlurType"
import { BorderPosition } from "../enums/SDKBorderPosition"
import { BorderStyle } from "../enums/SDKBorderStyle"
import { GradientType } from "../enums/SDKGradientType"
import { ShadowType } from "../enums/SDKShadowType"
import { TokenType } from "../enums/SDKTokenType"
import {
  LineHeightUnit,
  MsUnit,
  PxUnit,
  RawUnit,
  SizeUnit,
  Unit,
} from "../enums/SDKUnit"
import { TokenOrigin } from "../support/SDKTokenOrigin"

import { BlurToken } from "./SDKBlurToken"
import { BorderToken } from "./SDKBorderToken"
import { ColorToken } from "./SDKColorToken"
import {
  AnyDimensionToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  BorderWidthToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DimensionToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DurationToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FontSizeToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  LetterSpacingToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  LineHeightToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OpacityToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ParagraphSpacingToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RadiusToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SizeToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SpaceToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ZIndexToken,
} from "./SDKDimensionToken"
import { GradientToken } from "./SDKGradientToken"
import { ShadowToken } from "./SDKShadowToken"
import {
  AnyStringToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FontFamilyToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FontWeightToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ProductCopyToken, // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  StringToken,
} from "./SDKStringToken"
import { TextCaseToken } from "./SDKTextCaseToken"
import { TextDecorationToken } from "./SDKTextDecorationToken"
import { Token } from "./SDKToken"
import { TypographyToken } from "./SDKTypographyToken"
import { VisibilityToken } from "./SDKVisibilityToken"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Remote Valu Definitions

export type TokenValue = {
  id: string
  name: string
  description: string
  tokenType: TokenType
  origin: TokenOrigin | null
}

export type ColorTokenValue = {
  color: ColorValue
  opacity: OpacityTokenValue
  referencedTokenId: string | null
}

export type ColorValue = {
  r: number
  g: number
  b: number
  referencedTokenId: string | null
}

export type TypographyTokenValueTypes =
  | FontFamilyTokenValue
  | FontWeightTokenValue
  | FontSizeTokenValue
  | TextDecorationTokenValue
  | TextCaseTokenValue
  | LetterSpacingTokenValue
  | LineHeightTokenValue
  | ParagraphSpacingTokenValue

export type TypographyTokenValue = {
  fontFamily: FontFamilyTokenValue
  fontWeight: FontWeightTokenValue
  fontSize: FontSizeTokenValue
  textDecoration: TextDecorationTokenValue
  textCase: TextCaseTokenValue
  letterSpacing: LetterSpacingTokenValue
  lineHeight: LineHeightTokenValue | null
  paragraphIndent: ParagraphSpacingTokenValue
  paragraphSpacing: ParagraphSpacingTokenValue
  referencedTokenId: string | null
}

export type ShadowTokenValue = {
  color: ColorTokenValue
  x: number
  y: number
  radius: number
  spread: number
  opacity?: OpacityTokenValue
  type: ShadowType
  referencedTokenId: string | null
}

export type DimensionTokenValue = {
  unit: Unit
  measure: number
  referencedTokenId: string | null
}

export type SizeTokenValue = {
  unit: SizeUnit
  measure: number
  referencedTokenId: string | null
}

export type SpaceTokenValue = {
  unit: SizeUnit
  measure: number
  referencedTokenId: string | null
}

export type OpacityTokenValue = {
  unit: RawUnit
  measure: number
  referencedTokenId: string | null
}

export type FontSizeTokenValue = {
  unit: SizeUnit
  measure: number
  referencedTokenId: string | null
}

export type LineHeightTokenValue = {
  unit: LineHeightUnit
  measure: number
  referencedTokenId: string | null
}

export type LetterSpacingTokenValue = {
  unit: SizeUnit
  measure: number
  referencedTokenId: string | null
}

export type ParagraphSpacingTokenValue = {
  unit: SizeUnit
  measure: number
  referencedTokenId: string | null
}

export type BorderWidthTokenValue = {
  unit: PxUnit
  measure: number
  referencedTokenId: string | null
}

export type RadiusTokenValue = {
  unit: PxUnit
  measure: number
  referencedTokenId: string | null
}

export type DurationTokenValue = {
  unit: MsUnit
  measure: number
  referencedTokenId: string | null
}

export type ZIndexTokenValue = {
  unit: RawUnit
  measure: number
  referencedTokenId: string | null
}

export type AnyDimensionTokenValue =
  | DimensionTokenValue
  | SizeTokenValue
  | SpaceTokenValue
  | OpacityTokenValue
  | FontSizeTokenValue
  | LineHeightTokenValue
  | LetterSpacingTokenValue
  | ParagraphSpacingTokenValue
  | BorderWidthTokenValue
  | RadiusTokenValue
  | DurationTokenValue
  | ZIndexTokenValue

export type BorderTokenValue = {
  color: ColorTokenValue
  width: BorderWidthTokenValue
  position: BorderPosition
  style: BorderStyle
  referencedTokenId: string | null
}

export type GradientTokenValue = {
  to: {
    x: number
    y: number
  }
  from: {
    x: number
    y: number
  }
  type: GradientType
  aspectRatio: number
  stops: Array<GradientStopValue>
  referencedTokenId: string | null
}

export type GradientStopValue = {
  position: number
  color: ColorTokenValue
}

export type StringTokenValue = {
  text: string
  referencedTokenId: string | null
}

export type ProductCopyTokenValue = {
  text: string
  referencedTokenId: string | null
}

export type FontFamilyTokenValue = {
  text: string
  referencedTokenId: string | null
}

export type FontWeightTokenValue = {
  text: string
  referencedTokenId: string | null
}

export type AnyStringTokenValue =
  | StringTokenValue
  | ProductCopyTokenValue
  | FontFamilyTokenValue
  | FontWeightTokenValue

// TODO: Make options typed with enum inside types
export type TextCaseTokenValue = {
  value: string
  options?: string[]
  referencedTokenId: string | null
}

export type TextDecorationTokenValue = {
  value: string
  options?: string[]
  referencedTokenId: string | null
}

export type VisibilityTokenValue = {
  value: string
  options?: string[]
  referencedTokenId: string | null
}

export type AnyOptionTokenValue =
  | TextCaseTokenValue
  | TextDecorationTokenValue
  | VisibilityTokenValue

export type AnyOptionToken =
  | TextCaseToken
  | TextDecorationToken
  | VisibilityToken

export type BlurTokenValue = {
  type: BlurType
  radius: DimensionTokenValue
  referencedTokenId: string | null
}

export type AnyTokenValue =
  | ColorTokenValue
  | AnyStringTokenValue
  | TypographyTokenValue
  | ShadowTokenValue[]
  | AnyDimensionTokenValue
  | BorderTokenValue
  | GradientTokenValue[]
  | BlurTokenValue
  | AnyOptionTokenValue

export type AnyToken =
  | ColorToken
  | AnyStringToken
  | TypographyToken
  | ShadowToken
  | AnyDimensionToken
  | BorderToken
  | GradientToken
  | BlurToken
  | AnyOptionToken

export type AnySingleLayerToken = Exclude<AnyToken, ShadowToken | GradientToken>
export type AnyMultiLayerToken = ShadowToken | GradientToken
export type AnyMultiLayerTokenValue = ShadowTokenValue | GradientTokenValue
export type AnyPlainTokenValue =
  | Exclude<AnyTokenValue, ShadowTokenValue[] | GradientTokenValue[]>
  | ShadowTokenValue
  | GradientTokenValue

export type RawValue<T extends { referencedTokenId: string }> = Omit<
  T,
  "referencedTokenId"
>

export const getReferencedTokens = (
  token: AnyToken,
  allTokens: Map<string, Token>
) => {
  if (token instanceof ShadowToken || token instanceof GradientToken) {
    // TODO:fix-sdk-eslint
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    return token.value.map((v) => allTokens.get(v.referencedTokenId))
  }

  if (token.value.referencedTokenId) {
    return [allTokens.get(token.value.referencedTokenId)]
  }

  return []
}

export const getSingleReferencedTokenId = (
  token: AnyToken,
  allTokens: Map<string, Token>
): string | undefined => {
  const refs = getReferencedTokens(token, allTokens)

  if (refs.length !== 1 || !refs[0]) {
    return undefined
  }

  return refs[0].id
}

export const checkSingleReferencedTokenById = (
  token: AnyToken,
  candidateId: string,
  allTokens: Map<string, Token>
) => {
  return (
    candidateId && getSingleReferencedTokenId(token, allTokens) === candidateId
  )
}

export const replaceReferencedTokenIds = (
  token: AnyToken,
  currentId: string,
  newId: string,
  allTokens: Map<string, Token>
) => {
  for (const t of getReferencedTokens(token, allTokens)) {
    if (t && t.id === currentId) {
      t.id = newId
    }
  }
}

export const replaceReferencedToken = (
  token: Token,
  existingToken: AnyToken,
  newToken: AnyToken
) => {
  replaceReferencedTokenByIds(token, existingToken.id, newToken.id)
  replaceOriginReferencePersistentIdByIds(token, existingToken.id, newToken.id)
}

export const replaceReferencedTokenByIds = (
  token: Token,
  existingTokenId: string,
  newTokenId: string
) => {
  const value = (token as AnyToken)?.value

  if (!value) {
    // Do nothing if there is no value
  } else if (!Array.isArray(value)) {
    if (value?.referencedTokenId === existingTokenId) {
      value.referencedTokenId = newTokenId
    }
  } else {
    for (const item of value) {
      // FIXME: Deep layers
      if (item?.referencedTokenId === existingTokenId) {
        item.referencedTokenId = newTokenId
      }
    }
  }
}

export const replaceOriginReferencePersistentIdByIds = (
  token: Token,
  existingTokenId: string,
  newTokenId: string
) => {
  const origin = (token as AnyToken)?.origin

  if (!origin?.referencePersistentId) {
    // Do nothing if there is no origin
  } else if (
    origin.referencePersistentId &&
    !Array.isArray(origin.referencePersistentId)
  ) {
    if (origin.referencePersistentId === existingTokenId) {
      origin.referencePersistentId = newTokenId
    }
  }
}
