import { getSegment } from "@supernovaio/cloud/features/segment/getSegment"

export async function resetSegment() {
  await getSegment().reset()
}

/*
 * Utility function that converts an object's keys to a snake case.
 */
export function convertKeysToSnakeCase(
  obj: Record<string, unknown>,
  keysToSkip: string[] = []
): Record<string, unknown> {
  return Object.keys(obj).reduce((result, key) => {
    const newKey = keysToSkip.includes(key) ? key : toSnakeCase(key)

    // eslint-disable-next-line no-param-reassign
    result[newKey] = obj[key]
    return result
  }, {} as Record<string, unknown>)
}

/**
 * Utility function that appends a prefix to an object's keys,
 * except for the keys specified in an exceptions list.
 */
export function addKeysPrefix(
  obj: Record<string, unknown>,
  prefix: string,
  keysToSkip: string[] = []
): Record<string, unknown> {
  return Object.keys(obj).reduce((result, key) => {
    const newKey = keysToSkip.includes(key) ? key : `${prefix}_${key}`

    // eslint-disable-next-line no-param-reassign
    result[newKey] = obj[key]
    return result
  }, {} as Record<string, unknown>)
}

function toSnakeCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
}
