//
//  SDKDesignComponent.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOFigmaComponent } from "@supernova-studio/client"

import { Source } from "../support/SDKSource"

import { DesignComponentBase } from "./SDKDesignComponentBase"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definitions

export type DesignComponentRemoteModel = DTOFigmaComponent

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class DesignComponentVariant extends DesignComponentBase {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  variantPropertyValues: Record<string, string> | null

  parentComponentPersistentId: string | null

  isAsset: boolean | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DesignComponentRemoteModel, sources: Array<Source>) {
    super(model, sources)
    this.variantPropertyValues = model.variantPropertyValues ?? null
    this.parentComponentPersistentId = model.parentComponentPersistentId ?? null
    this.isAsset = model.exportProperties.isAsset ?? null
  }
}
