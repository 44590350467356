//
//  SDKDesignSystemContactList.ts
//  Supernova SDK
//
//  Created by Vsevolod Krasnov.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTODesignSystemContactsResponse,
  DTODesignSystemMemberListResponse,
} from "@supernova-studio/client"

import {
  convertRemoteToDesignSystemRole,
  DesignSystemRole,
} from "../enums/SDKDesignSystemRole"
import { InspectableUser } from "../users/SDKInspectableUser"
import { User } from "../users/SDKUser"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Model Definition

export class DesignSystemContactList {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** List of users that have managing permissions on the workspace-level. */
  workspace: Array<InspectableUser>

  /** List of users that have managing permissions on the design system-level. */
  designSystem: Array<InspectableUser>

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTODesignSystemContactsResponse) {
    this.workspace = model.contacts.workspace.map((u) => {
      return new InspectableUser(u)
    })
    this.designSystem = model.contacts.designSystem.map((u) => {
      return new InspectableUser(u)
    })
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Utilities

  /**
   * Get the list of emails that have managing permissions on the workspace-level.
   */
  getWorkspaceEmails(): Array<string> {
    return this.workspace.map((u) => u.email)
  }

  /**
   * Get the list of emails that have managing permissions on the design system-level.
   */
  getDesignSystemEmails(): Array<string> {
    // Fallback to workspace if there are no design system contacts
    const users =
      this.designSystem.length > 0 ? this.designSystem : this.workspace

    return users.map((u) => u.email)
  }
}
