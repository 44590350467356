//
//  SDKInspectableUser.ts
//  Supernova SDK
//
//  Created by Vsevolod Krasnov.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTOUser } from "@supernova-studio/client"

import {
  UserProfile,
  UserProfileRemoteModel,
  UserProfileTransportModel,
} from "./SDKUserProfile"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -- - --- --- --- --- --- --- --- --- --- ---
// MARK: -  Model Definition

/** Model that describes a user that can be inspected by others.
 * It has only public properties and no sensitive data.
 */
export class InspectableUser {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Unique user identifier */
  id: string

  /** Unique user email */
  email: string

  /** User name */
  name: string

  /** User nickname */
  nickname: string | null

  /** Avatar */
  avatar: string | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTOUser) {
    this.id = model.id
    this.email = model.email
    this.name = model.profile.name
    this.nickname = model.profile.nickname ?? null
    this.avatar = model.profile.avatar ?? null
  }
}
