//
//  SDKToken.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { ElementProperty } from "../elements/SDKElementProperty"
import { ElementPropertyValue } from "../elements/values/SDKElementPropertyValue"
import { TokenType } from "../enums/SDKTokenType"
// TODO:fix-sdk-eslint
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TokenGroup } from "../groups/SDKTokenGroup"
import { TokenOrigin } from "../support/SDKTokenOrigin"

import { TokenRemoteModel } from "./remote/SDKRemoteTokenModel"

import { TokenValue } from "./SDKTokenValue"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Definitions

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: -  Object Definition

export class Token implements TokenValue {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Public properties

  /** Persistent id of the token. This id never changes across versions */
  // @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
  id: string

  /** Version id of the token. This id changes whenever a new version is created, and each version of the token in version has different id */
  // @ts-expect-error TS(2564): Property 'idInVersion' has no initializer and is n... Remove this comment to see the full error message
  idInVersion: string

  /** Persistent id of the brand. This id never changes as tokens can't be moved across the brands */
  // @ts-expect-error TS(2564): Property 'brandId' has no initializer and is not d... Remove this comment to see the full error message
  brandId: string

  // @ts-expect-error TS(2564): Property 'themeId' has no initializer and is not d... Remove this comment to see the full error message
  themeId: string | null

  // @ts-expect-error TS(2564): Property 'designSystemVersionId' has no initialize... Remove this comment to see the full error message
  designSystemVersionId: string

  // @ts-expect-error TS(2564): Property 'collectionId' has no initializer and is... Remove this comment to see the full error message
  collectionId: string | null

  // @ts-expect-error TS(2564): Property 'name' has no initializer and is not defi... Remove this comment to see the full error message
  name: string

  // @ts-expect-error TS(2564): Property 'description' has no initializer and is n... Remove this comment to see the full error message
  description: string

  // @ts-expect-error TS(2564): Property 'tokenType' has no initializer and is not... Remove this comment to see the full error message
  tokenType: TokenType

  // @ts-expect-error TS(2564): Property 'origin' has no initializer and is not de... Remove this comment to see the full error message
  origin: TokenOrigin | null

  // @ts-expect-error TS(2564): Property 'parentGroupId' has no initializer and is... Remove this comment to see the full error message
  parentGroupId: string

  // @ts-expect-error TS(2564): Property 'tokenPath' has no initializer and is not... Remove this comment to see the full error message
  tokenPath: Array<string> | null

  // @ts-expect-error TS(2564): Property 'sortOrder' has no initializer and is not... Remove this comment to see the full error message
  sortOrder: number

  // @ts-expect-error TS(2564): Property 'properties' has no initializer and is no... Remove this comment to see the full error message
  properties: Array<ElementProperty>

  // @ts-expect-error TS(2564): Property 'propertyValues' has no initializer and i... Remove this comment to see the full error message
  propertyValues: { [key: string]: string | boolean | number }

  // @ts-expect-error TS(2564): Property 'createdAt' has no initializer and is not... Remove this comment to see the full error message
  createdAt: Date | null

  // @ts-expect-error TS(2564): Property 'updatedAt' has no initializer and is not... Remove this comment to see the full error message
  updatedAt: Date | null

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(
    model: TokenRemoteModel,
    versionId: string,
    properties: Array<ElementProperty>,
    propertyValues: Array<ElementPropertyValue>,
    empty = false
  ) {
    if (empty) {
      // For creation of empty token to be filled from the outside
      return
    }

    this.id = model.persistentId
    this.idInVersion = model.id
    this.brandId = model.brandId
    this.themeId = null // Can only be set outside
    this.designSystemVersionId = versionId
    this.collectionId = model.collectionId ?? null

    if (model.meta) {
      this.name = model.meta.name
      this.description = model.meta.description
    }

    this.tokenType = model.type
    this.origin = model.originStyle ? new TokenOrigin(model.originStyle) : null
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    this.parentGroupId = null
    this.tokenPath = null
    this.createdAt = model.createdAt ? new Date(model.createdAt) : null
    this.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null
    // Set unordered when constructing
    this.sortOrder = model.originStyle?.sortOrder ?? -1
    this.properties = properties
    this.propertyValues = {}

    for (const value of propertyValues) {
      if (value.targetElementId === this.id) {
        // Property value refers to this element
        for (const property of properties) {
          if (property.id === value.definitionId) {
            // Property value refers to the correct property, we get codeName from it and add it to quick-access
            this.propertyValues[property.codeName] = value.value
          }
        }
      }
    }
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Manipulation

  setParentGroupId(parentGroupId: string | null) {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    this.parentGroupId = parentGroupId ?? null
  }

  setTokenPath(tokenPath: Array<string>) {
    this.tokenPath = tokenPath
  }

  setSortOrder(order: number) {
    this.sortOrder = order
  }

  toBaseWriteObject(): TokenRemoteModel {
    return {
      id: this.idInVersion,
      brandId: this.brandId,
      designSystemVersionId: this.designSystemVersionId,
      persistentId: this.id,
      type: this.tokenType,
      meta: {
        name: this.name,
        description: this.description ?? "",
      },
      originStyle:
        this.origin && this.origin.id && this.origin.sourceId
          ? {
              id: this.origin.id ?? undefined,
              name: this.origin.name ?? undefined,
              sourceId: this.origin.sourceId ?? undefined,
              referenceOriginId: this.origin.referenceOriginId ?? undefined,
              referencePersistentId:
                this.origin.referencePersistentId ?? undefined,
              referenceResolutionFailed:
                this.origin.referenceResolutionFailed ?? undefined,
              referenceOriginRemote:
                this.origin.referenceOriginRemote ?? undefined,
              referenceOriginName: this.origin.referenceOriginName ?? undefined,
              key: this.origin.key ?? undefined,
            }
          : undefined,
      customPropertyOverrides: [],
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type '{ alia... Remove this comment to see the full error message
      data: undefined,
    }
  }

  toWriteObject(): TokenRemoteModel {
    throw Error("Unable to write generic token")
  }
}
