//
//  AreaDesignSystems.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTODocumentationLinkPreviewRequest,
  DTODocumentationPageApprovalStateChangeInput,
} from "@supernova-studio/client"
import {
  DesignSystemInvite,
  DesignSystemInviteUpdate,
  DesignSystemMemberUpdate,
  DesignSystemPendingMemberInvite,
  DesignSystemUserInvitation,
} from "@supernova-studio/model"

import {
  DesignSystem,
  DesignSystemAccessMode,
  DesignSystemSwitcher,
} from "../../model/base/SDKDesignSystem"
import { DesignSystemContactList } from "../../model/base/SDKDesignSystemContactList"
import { DesignSystemMemberList } from "../../model/base/SDKDesignSystemMemberList"
import { CustomDomain } from "../../model/support/SDKCustomDomain"
import { DataCore } from "../data/SDKDataCore"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Users Area

export class AreaDesignSystems {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  /** Internal: Engine */
  private dataCore: DataCore

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(dataCore: DataCore) {
    this.dataCore = dataCore
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Read

  /** Fetches all design systems belonging to the workspace
   * @param workspaceId - Workspace to fetch from
   * @returns All design systems in the specified version
   */
  async designSystems(workspaceId: string): Promise<Array<DesignSystem>> {
    return this.dataCore.designSystems(workspaceId)
  }

  /** Fetches a specific design system by its id
   * @param designSystemId - Design system to fetch
   * @returns Design system with the specified id
   */
  async designSystem(designSystemId: string): Promise<DesignSystem | null> {
    return this.dataCore.designSystem(designSystemId)
  }

  /** Retrieves custom domain information for a design system
   * @param designSystemId - ID of the design system
   * @returns custom domain information
   */
  async designSystemCustomDomain(
    designSystemId: string
  ): Promise<CustomDomain> {
    return this.dataCore.designSystemCustomDomain(designSystemId)
  }

  /** Fetches design system stats
   * @param dsId - Design system ID
   * @param versionId - Version ID
   * @param brandId - Brand ID to filter by
   * @returns Design system stats
   */
  async designSystemStats(dsId: string, versionId: string, brandId?: string) {
    return this.dataCore.designSystemStats(dsId, versionId, brandId)
  }

  /** Fetches specific design system's memberships
   * @param designSystemId - Design system's identifier
   * @returns Design system's memberships
   */
  async designSystemMemberList(
    designSystemId: string
  ): Promise<DesignSystemMemberList> {
    return this.dataCore.designSystemMemberList(designSystemId)
  }

  /** Fetches specific design system's contacts
   * @param designSystemId - Design system's identifier
   * @returns Design system's contacts
   */
  async designSystemContactList(
    designSystemId: string
  ): Promise<DesignSystemContactList> {
    return this.dataCore.designSystemContacts(designSystemId)
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Create/Update

  /** Creates a new design system and retrieves the newly created DS object
   * @param workspaceId - Workspace to create the design system in
   * @param name - Name of the design system
   * @param description - Description of the design system
   * @param accessMode - Defines who can access the design system
   * @param usersToInvite - Existing users to invite
   * @param invitesToInvite - Pending memberships to invite
   * @param emailsToInvite - New emails to invite
   * @returns Newly created design system object
   */
  async createDesignSystem(
    workspaceId: string,
    name: string,
    description?: string,
    accessMode?: DesignSystemAccessMode,
    usersToInvite?: Array<DesignSystemUserInvitation>,
    invitesToInvite?: Array<DesignSystemPendingMemberInvite>,
    emailsToInvite?: Array<DesignSystemInvite>
  ): Promise<DesignSystem> {
    return this.dataCore.createDesignSystem(
      workspaceId,
      name,
      description,
      accessMode,
      usersToInvite,
      invitesToInvite,
      emailsToInvite
    )
  }

  async getBlockDefinitions(designSystemId: string, versionId: string) {
    return this.dataCore.getBlockDefinitions(designSystemId, versionId)
  }

  async getUrlPreview(
    designSystemId: string,
    versionId: string,
    request: DTODocumentationLinkPreviewRequest
  ) {
    return this.dataCore.getUrlPreview(designSystemId, versionId, request)
  }

  /** Updates design system metadata for a specific design system
   * @param designSystemId - ID of the design system
   * @param name - New DS name
   * @param description - New DS description
   * @returns Nothing
   */
  async updateDesignSystemMetadata(
    designSystemId: string,
    name: string,
    description?: string
  ): Promise<void> {
    return this.dataCore.updateDesignSystemMetadata(
      designSystemId,
      name,
      description
    )
  }

  /** Enabled or disables multibrand functionality for a specific design system
   * @param designSystem - design system to update
   * @param isMultibrand - Multibrand functionality enabled or disabled
   * @returns Nothing
   */
  async updateDesignSystemIsMultibrand(
    designSystem: DesignSystem,
    isMultibrand: boolean
  ): Promise<void> {
    return this.dataCore.updateDesignSystemIsMultibrand(
      designSystem,
      isMultibrand
    )
  }

  /** Updates a design system approval feature
   * @param designSystemId - design system to update
   * @param payload - Approvals feature data
   * @returns Nothing
   */
  async updateDesignSystemIsApprovalFeatureEnabled(
    designSystemId: string,
    payload: {
      isApprovalFeatureEnabled?: boolean
      approvalRequiredForPublishing?: boolean
    }
  ): Promise<void> {
    return this.dataCore.updateDesignSystemApprovalsFeature(
      designSystemId,
      payload
    )
  }

  /** Updates approval state of a page
   * @param designSystemId - design system of the page
   * @param versionId - version of the page
   * @param payload - approval state info
   * @returns Nothing
   */
  async updateApprovalState(
    designSystemId: string,
    versionId: string,
    payload: DTODocumentationPageApprovalStateChangeInput
  ) {
    return this.dataCore.updateApprovalState(designSystemId, versionId, payload)
  }

  /** Enabled or disables multibrand functionality for a specific design system
   * @param designSystem - design system to update
   * @param designSystemSwitcher - Design system switcher options
   * @returns Nothing
   */
  async updateDesignSystemSwitcher(
    designSystem: DesignSystem,
    designSystemSwitcher: DesignSystemSwitcher
  ): Promise<void> {
    return this.dataCore.updateDesignSystemSwitcher(
      designSystem,
      designSystemSwitcher
    )
  }

  /** Enabled or disables multibrand functionality for a specific design system
   * @param designSystem - design system to update
   * @param docExporterId - Design system doc exporter id
   * @returns Nothing
   */
  async updateDesignSystemDocExporterId(
    designSystem: DesignSystem,
    docExporterId: string
  ): Promise<void> {
    return this.dataCore.updateDesignSystemDocExporterId(
      designSystem,
      docExporterId
    )
  }

  /** Changes documentation slug for design system
   * @param designSystem - design system to update
   * @param docUserSlug - Documentation slug
   * @returns Nothing
   */
  async updateDesignSystemDocumentationSlug(
    designSystem: DesignSystem,
    docUserSlug: string
  ): Promise<void> {
    return this.dataCore.updateDesignSystemDocumentationSlug(
      designSystem,
      docUserSlug
    )
  }

  /** Updates custom domain of a specific design system
   * @param designSystemId - ID of the design system
   * @param newDomain - new custom domain
   * @returns nothing
   */
  async updateDesignSystemCustomDomain(
    designSystemId: string,
    newDomain: string
  ): Promise<CustomDomain> {
    return this.dataCore.updateCustomDomain(designSystemId, newDomain)
  }

  /** Updates design system's access mode and, if switching to invite-only, also the member list
   * @param designSystemId - ID of the design system
   * @param accessMode - new access mode
   * @param retainUserIds - which users to keep when switching to invite-only
   * @param retainInviteIds - which invites to keep when switching to invite-only
   * @returns nothing
   */
  async updateDesignSystemAccessAndMemberList(
    designSystemId: string,
    accessMode: DesignSystemAccessMode,
    retainUserIds: Array<string>,
    retainInviteIds: Array<string>
  ): Promise<void> {
    return this.dataCore.updateDesignSystemAccessModeAndMemberList(
      designSystemId,
      accessMode,
      retainUserIds,
      retainInviteIds
    )
  }

  /** Updates design system's member list
   * @param designSystemId - ID of the design system
   * @param usersToInvite - Existing users to invite
   * @param invitesToInvite - Pending memberships to invite
   * @param emailsToInvite - New emails to invite
   * @param invitesToUpdate - Pending memberships to change dsRole
   * @param deleteInvitationIds - Pending memberships to delete
   * @param usersToUpdate - Existing users to change dsRole
   * @param removeUserIds - Existing users to remove
   * @returns nothing
   */
  async updateDesignSystemMemberList(
    designSystemId: string,
    usersToInvite?: Array<DesignSystemUserInvitation>,
    invitesToInvite?: Array<DesignSystemPendingMemberInvite>,
    emailsToInvite?: Array<DesignSystemInvite>,
    invitesToUpdate?: Array<DesignSystemInviteUpdate>,
    deleteInvitationIds?: Array<string>,
    usersToUpdate?: Array<DesignSystemMemberUpdate>,
    removeUserIds?: Array<string>
  ): Promise<void> {
    return this.dataCore.updateDesignSystemMemberList(
      designSystemId,
      usersToInvite,
      invitesToInvite,
      emailsToInvite,
      invitesToUpdate,
      deleteInvitationIds,
      usersToUpdate,
      removeUserIds
    )
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Delete

  /** Deletes a design system. This action is irreversible, all data will be lost, and can only be performed by the admins of the workspace
   * @param designSystemId - ID of the design system
   * @returns Nothing. RIP design system.
   */
  async deleteDesignSystem(designSystemId: string): Promise<void> {
    return this.dataCore.deleteDesignSystem(designSystemId)
  }

  /** Deletes custom domain
   * @param designSystemId - ID of the design system
   * @returns nothing
   */
  async deleteDesignSystemCustomDomain(designSystemId: string): Promise<void> {
    return this.dataCore.deleteCustomDomain(designSystemId)
  }

  /** starts ssl generation for custom domain
   * @param designSystemId - ID of the design system
   * @returns nothing
   */
  async startSslGenerationForCustomDomain(
    designSystemId: string
  ): Promise<void> {
    return this.dataCore.startSslGenerationForCustomDomain(designSystemId)
  }
}
