//
//  DesignSystemVersionStats.ts
//  Supernova SDK
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { DTODesignSystemVersionStats } from "@supernova-studio/client"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class DesignSystemVersionStats {
  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Properties

  tokens: number

  designSystemComponents: number

  assets: number

  documentationPages: number

  figmaComponents: number

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(model: DTODesignSystemVersionStats) {
    this.tokens = model.tokens
    this.designSystemComponents = model.designSystemComponents
    this.assets = model.assets
    this.documentationPages = model.documentationPages
    this.figmaComponents = model.figmaComponents
  }

  // --- --- --- --- --- --- --- --- --- ---
  // MARK: - Serialization & Deserialization

  /** Constructs representation that can be used to write full object to remote */
  toRemote(): DTODesignSystemVersionStats {
    return {
      tokens: this.tokens,
      designSystemComponents: this.designSystemComponents,
      assets: this.assets,
      documentationPages: this.documentationPages,
      figmaComponents: this.figmaComponents,
    }
  }
}
