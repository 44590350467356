//
//  SDKDocsBlockImageProperty.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import { Alignment } from "../../../enums/SDKAlignment"
import { DocsBlockImagePropertyAspectRatio } from "../../../enums/SDKDocsBlockImagePropertyAspectRatio"
import { DocsBlockImagePropertyWidth } from "../../../enums/SDKDocsBlockImagePropertyWidth"
import { DocsBlockItemPropertyType } from "../../../enums/SDKDocsBlockItemPropertyType"
import { DocsImageRef } from "../support/SDKDocsImageRef"

import {
  DocsBlockPropertyDefinition,
  DocsBlockPropertyDefinitionModel,
  DocsBlockPropertyData,
} from "./SDKDocsBlockProperty"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Definition

/** Image property - Additional non-value options */
export type DocsBlockImagePropertyOptions = {
  // The width of the image from predefined options. If not defined, it's 100%
  width?: DocsBlockImagePropertyWidth
  // If true, the image will be displayed in full width. If not defined, it's auto
  aspectRatio?: DocsBlockImagePropertyAspectRatio
  // If true, the image will allow to add caption. If not defined, it's true
  allowCaption?: boolean
  // If true, the image will allow to enter fullscreen/lightbox mode. If not defined, it's false
  allowLightbox?: boolean
  // If true, the image will show option to set a border. If not defined, it's false
  allowBorder?: boolean
  // If filled, we show recommendation for the image in property panel
  recommendation?: string
}

/** Image property - Data */
export type DocsBlockImagePropertyData = DocsBlockPropertyData<
  DocsImageRef | undefined
> & {
  // Image alternative text. Default: empty
  alt: string
  // Image caption. Default: empty
  caption?: string
  // Aligns the image and the caption to the left, center or full width. Default: left
  alignment?: Alignment
  // If true, the image will open in lightbox. Default: false
  openLightbox?: boolean
  // If true, the image will have a border. Default: false
  isBordered?: boolean
}

/** Image property - Definition model */
export type DocsBlockImagePropertyDefinitionModel =
  DocsBlockPropertyDefinitionModel & DocsBlockImagePropertyOptions

/** Image property - Definition */
export class DocsBlockImagePropertyDefinition extends DocsBlockPropertyDefinition<
  DocsBlockImagePropertyOptions,
  DocsBlockImagePropertyData
> {
  type!: DocsBlockItemPropertyType.image
}
